.validation-line-table.white-space-nowrap tbody td,
.validation-line-table.white-space-nowrap thead th,
.validation-line-table.white-space-nowrap td,
.validation-line-table.white-space-nowrap th{
    white-space: nowrap!important;
}

.validation-line-table.bordered tbody td,
.validation-line-table.bordered thead th,
.validation-line-table.bordered td,
.validation-line-table.bordered th {
    border: 1px solid gray;
}