

.colnav{
    color:#222;
    margin-bottom: -2px;
}


.ant-menu-item{
    padding: 0!important;
}

.ant-menu-item a{
    padding-left: 24px!important;
    width: 100%;
}
.ant-menu-item a.selected{
    background-color: rgb(230, 247, 255);
    height:100%;
    border-right:3px solid rgb(49, 84, 161)
}



.ant-menu-item-only-child {
    padding-left: 60px;
    font-size: 16px;
    margin-bottom: 0px!important;
}

.menuside li:hover{

    background-color: rgb(230, 247, 255);
}


.ant-menu-inline > .ant-menu-item {
    height:45px!important;
}


.ant-menu-inline .ant-menu-item::after {
    border-right: 0!important;
}

.ant-menu-submenu > .ant-menu {

    background-color: transparent!important;

}