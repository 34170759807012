.attachment-preview .actions-container {
    display:  none;
    position: absolute;
    top: 45%;
    left : 45%;
    right : auto;
    bottom : auto;
}
.attachment-preview:hover .actions-container{
    display: "flex";
    z-index: 1000;
}